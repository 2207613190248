import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";

// Container for the tabs
const StyledTabsContainer = styled.div`
  position: relative;
  list-style: none;
   white-space: nowrap;
   overflow-x: auto; 
  display: flex;
  align-items: center;
  background-color: #fff; /* Background color for the container */
  border-radius: 99px; /* Rounded corners for the tabs container */
  overflow: hidden; /* Hide overflow from the sliding background */
             border-radius: 29px;
    box-shadow: 0px 4px 40px 0px #0000000F;
        
  
  padding: 6px; /* Padding to create space around the tabs */
  margin:0;
  /* Flex layout for large screens */
  @media (min-width: 601px) {
    justify-content: space-between; /* Distribute tabs with space between them */
    overflow: hidden; /* Hide any overflow */
  }
  
  /* Media query for small screens */
  @media (max-width: 1250px) {
    overflow-x: auto; /* Enable horizontal scrolling */
    white-space: nowrap; /* Prevent line breaks */
    padding: 8px 0; /* Adjust padding for small screens */
    
    /* Hide scrollbar for better appearance */
    &::-webkit-scrollbar {
      display: none; /* For WebKit browsers (Chrome, Safari) */
    }
  }
`;

// Background that slides beneath the buttons
const SlidingBackground = styled.div`
  position: absolute;
  top: ${(props) => props.top}; /* Position the sliding background */
  left: ${(props) => props.left}; /* Position the sliding background */
  height: ${(props) => props.height}; /* Dynamic height based on active tab */
  width: ${(props) => props.width}; /* Dynamic width based on active tab */
   min-width: 150px; /* Minimum width of the sliding background */
  background-color: #e1596d; /* Background color for the sliding effect */
  transition: top 0.3s ease, left 0.3s ease, height 0.3s ease, width 0.3s ease; /* Smooth sliding and resizing transition */
  z-index: 1; /* Ensure it is below the text but above the container */
  border-radius: 99px; /* Match rounded corners of the container */
`;

// Styled component for each tab
const StyledTab = styled.li`
  display: inline-block; /* Ensure tabs are displayed in a line for scrolling */
  position: relative;
  margin: 0 4px; /* Space between tabs */
  height: fit-content; /* Height based on content */
    min-width: 150px; /* Ensure minimum width for each tab */

  button {
    cursor: pointer;
    position: relative;
    z-index: 2; /* Ensure text is above the sliding background */
    transition: color 0.3s, background-color 0.3s, border 0.3s; /* Smooth transitions */
    color: ${(props) => (props.isFocused ? "#fff" : "#868686")}; /* Text color for focused and non-focused tabs */
    background-color: ${(props) => (props.isFocused ? "transparent" : "transparent")}; /* Background color for non-active tabs */
    font-size:16px;
    width: 100%;
    height: 100%;
    padding: 12px 10px; /* Padding inside each tab */
    box-sizing: border-box; /* Ensure padding and border are included in the element's total width and height */
    text-align: center; /* Center text */
    border-radius: 99px; /* Rounded corners */
    background-color: ${(props) => (props.isFocused ? "transparent" : "transparent")}; /* Transparent background for non-active tabs */
   line-height:'22.4px'
  }
`;

const DynamicButtonsTabs = ({ value, onChange, tabs }) => {
  const [activeTabSize, setActiveTabSize] = useState({
    width: "0px",
    height: "0px",
    top: "0px",
    left: "0px",
    color:'#ffffff'
  });
  const tabRefs = useRef([]);
  const tabCount = tabs.length;
  const activeIndex = tabs.findIndex((tab) => tab.id === value); 

  useEffect(() => {
    if (tabRefs.current[activeIndex]) {
      const tabElement = tabRefs.current[activeIndex];
      setActiveTabSize({
        width: `${tabElement.offsetWidth}px`,
        height: `${tabElement.offsetHeight}px`,
        top: `${tabElement.offsetTop}px`,
        left: `${tabElement.offsetLeft}px`,
      });
    }
  }, [activeIndex]);
  const handleTabClick = (index, id) => {
   
    onChange(id, index); 
  };
  return (
    <StyledTabsContainer>
      <SlidingBackground
        top={activeTabSize.top}
        left={activeTabSize.left}
        width={activeTabSize.width}
        height={activeTabSize.height}
      />
        {tabs.map((tab, index) => (
        <StyledTab
          key={tab.id}
          isFocused={value === tab.id}
          onClick={() => handleTabClick(index, tab.id)} 
          ref={(el) => tabRefs.current[index] = el} 
        >
          <button className="capitalize">{tab.experienceName}</button>
        </StyledTab>
      ))}
    </StyledTabsContainer>
  );
};

export default DynamicButtonsTabs;
