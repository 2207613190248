export function mapToDropdownArray(originalArray, experienceType) {
  return originalArray
    .filter(item => item.mainTitle != null && item.experienceType === experienceType)  
    .reduce((acc, curr) => {
      const existingMainTitle = acc.find(item => item.mainTitle === curr.mainTitle);

      if (existingMainTitle) {
        existingMainTitle.title.push(curr.title);
        existingMainTitle.title.sort((a, b) => a.localeCompare(b));
      } else {
        acc.push({
          id:curr.id,
          mainTitle: curr.mainTitle,
          title: [curr.title],
          svgPath: curr.svgPath || null,
          thumbnail: curr.thumbnail || null,
          mainTitleIndex: curr.mainTitleIndex
        });
      }

      if (existingMainTitle && curr.svgPath) {
        existingMainTitle.svgPath = curr.svgPath;
      }

      return acc;
    }, []);
}
export function mapToTitleAndId(originalArray, experienceType) {
  return originalArray
    .filter(item => item.mainTitle != null && item.experienceType === experienceType)  
    .map(item => ({
      title: item.title,  
      id: item.id,
      games:item.games
    }));
}

export function getFilteredGames(games, type) {
  // Filter games based on the 'type' condition
  const filteredGames = games.filter(game =>
    type === 'webinar' ? game.isWebinar === true : game.isWebinar === false
  );

  // Remove duplicates by 'id'
  const uniqueGames = Array.from(
    new Map(filteredGames.map(game => [game.id, game])).values()
  );

  return uniqueGames;
}


export function searchObjectsByKey(array, key, value) {
  return array.filter(item => item[key] === value);
}